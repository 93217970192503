export function testRUT(rut: string) {
    if (/^0+/.test(rut)) return false;
    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) return false;
    if (rut.charAt(rut.length - 2) !== '-') return false;
    let cleanrut = rut.replace(/\./g, '');
    let pI = parseInt(cleanrut.slice(0, -1), 10);
    let m = 0;
    let s = 1;
    while (pI > 0) {
        s = (s + (pI % 10) * (9 - (m++ % 6))) % 11;
        pI = Math.floor(pI / 10);
    }
    const v = s > 0 ? '' + (s - 1) : 'K';
    return v === cleanrut.slice(-1);
}

export function formatRUT(RUT: string) {
    return RUT.replace(/[^0-9,k,K]/g, '').replace(
        /^(\d{1,2})(\d{3})(\d{3})(\w{1})$/,
        '$1.$2.$3-$4'
    );
}