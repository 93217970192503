import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-centralize-signature',
    templateUrl: './centralize-signature.component.html'
})

export class CentralizeSignatureComponent implements AfterContentInit {
    @ViewChild('iframe') iframe: ElementRef;

    iframeSrc = "https://survey.typeform.com/to/Gt0nMGbN";

    ngAfterContentInit(): void {
        setTimeout(() => {
            this.iframe.nativeElement.src = this.iframeSrc;
        }, 0);
    }
}
