import { AppType } from './../../../../../common/assets/website-data';
import { Component } from "@angular/core";
import { IDataModalTemplate } from "../../../../../common/interfaces/promotions.interface";

@Component({
    selector: 'app-dialog-promo-wrapper',
    templateUrl: './dialog-promo.component.html'
})

export class DialogPromoWrapperComponent {
    public dataModal: IDataModalTemplate = {
        title:{
            text: 'OBTEN 1 AÑO GRATIS',
            style: 'title bold'
        },
        subtitle: {
            text: '3 años de Firma Electrónica Simple al precio de 2',
            style: 'subtitle_many_text subtitle_secondary bold'
        },
        promoCode: {
            code: 'FIRMA3X2',
            style: 'promoCode_primary',
            borderStyle: 'promoCodeBorder'
        },
        button:{
            text: 'COMPRAR FIRMA',
            style: 'btn btnWhite2',
            buttonId: ''
        },
        footer: {
            text: '¡Agiliza tus trámites firmando en línea!'
        }
    }

    get appType() {
        return AppType;
    }
}