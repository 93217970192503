import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';
import { AutoatencionComponent } from './autoatencion/autoatencion.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
    {
        path: 'contactar-con-ventas/.',
        component: ContactWrapperComponent
    },
    {
        path: 'contactar-con-ventas',
        redirectTo: 'contactar-con-ventas/.',
        pathMatch: 'full'
    },
    {
        path: 'contactar-con-soporte/.',
        component: ContactSupportWrapperComponent
    },
    {
        path: 'contactar-con-soporte',
        redirectTo: 'contactar-con-soporte/.',
        pathMatch: 'full'
    }, {
        path: 'formas-de-pago/.',
        component: PaymentFormsWrapperComponent
    }, {
        path: 'formas-de-pago',
        redirectTo: 'formas-de-pago/.',
        pathMatch: 'full'
    },
    {
        path: 'autoatencion/.',
        component: AutoatencionComponent
    },
    {
        path: 'autoatencion',
        redirectTo: 'autoatencion/.',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
