import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { testRUT, formatRUT } from '../../../../../../common/functions/rut-functions';
import { MatDialog } from '@angular/material/dialog';
import { ApiAutoatencion } from '../../../shared/services/api-autoatencion';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { errorCodeChecker } from '../../autoatencion-common.component';

@Component({
    selector: 'app-cancel-revoke-signature',
    templateUrl: './cancel-revoke-signature.component.html',
})
export class CancelRevokeSignatureComponent implements OnInit {
    currentStep = 1;
    public testRUT = testRUT;
    public isLoading: boolean = false;
    public carnet: any;
    public inputRUT: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.pattern(/\b[0-9|.]{1,10}\-[K|k|0-9]/),
    ]);
    public inputUser: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
    ]);
    public inputMail: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.email,
    ]);
    public inputFile: FormControl = new FormControl('');
    public inputSelect: FormControl = new FormControl('', Validators.required);
    public inputMotivo: FormControl = new FormControl('');
    public form: FormGroup = new FormGroup({
        user: this.inputUser,
        RUT: this.inputRUT,
    });
    public mailForm: FormGroup = new FormGroup({
        mail: this.inputMail,
    });
    public tempMail: string = "";
    public fileForm: FormGroup = new FormGroup({
        file: this.inputFile,
    });
    public selectForm: FormGroup = new FormGroup({
        select: this.inputSelect,
        motivo: this.inputMotivo,
    });
    public uploadedFilesAsString = '';
    public fileInputState: 'valid' | 'invalid';
    private responseUser: any;
    private responseMail: any;
    private responseFinal: any;
    constructor(
        private dialog: MatDialog,
        private apiAutoatencion: ApiAutoatencion
    ) { }

    ngOnInit() {
    }

    nextStep(): void {
        this.currentStep++;
    }

    checkUser() {
        this.isLoading = true;
        const RUT = this.form.value.RUT.replaceAll('.', '')
        this.apiAutoatencion.postSignatureInfo(RUT, false).subscribe(res => { //Missing user check?
            this.responseUser = res;
            this.tempMail = this.responseUser.data.email_cert;
            this.isLoading = false;
        }, err => {
            let errCase = errorCodeChecker(err.error);
            this.errorFunction(errCase, 1);
            this.isLoading = false;
        }, () => {
            this.nextStep();
            this.isLoading = false;
        })
    }

    checkMail() {
        this.isLoading = true;
        const body = {
            "token": this.responseUser.data.token,
            "email": this.mailForm.value.mail
        }
        this.apiAutoatencion.validatingEmail(body).subscribe(res => {
            this.responseMail = res;
            this.nextStep();
            this.isLoading = false;
        }, err => {
            let errCase = errorCodeChecker(err.error);
            this.errorFunction(errCase, 3);
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        })
    }

    getImageObject(event: any): Promise<any> | void {
        const uploadedFiles = [];
        // limit file size
        //const Mb_4 = 4_194_304;
        const Mb_5 = 5_242_880;
        const fileInput: HTMLInputElement = event.target;
        const files = fileInput.files as FileList;
        // const firstFile = fileInput.files[0];
        for (let i = 0; i < files.length; i++) {
            const file = files[i] as File;
            if (file.size > Mb_5) {
                console.error(
                    'El tamaño del archivo no debe exceder los 5 megabytes.'
                );
                this.fileInputState = 'invalid';
                fileInput.value = null;
                this.uploadedFilesAsString = '';
                return;
            }

            // limit file extension
            const fileExtension =
                file.name.split('.')[file.name.split('.').length - 1];
            if (!['pdf'].includes(fileExtension)) {
                console.error('Solo puedes subir archivos .pdf.');
                this.fileInputState = 'invalid';
                fileInput.value = null;
                this.uploadedFilesAsString = '';
                return;
            }

            uploadedFiles.push(file.name);
        }

        if (uploadedFiles.length > 0) {
            this.uploadedFilesAsString = uploadedFiles.join(', ');
            this.fileInputState = 'valid';
            this.carnet = files;
        } else {
            if (
                uploadedFiles.length === 0 &&
                this.uploadedFilesAsString.length === 0
            ) {
                this.fileInputState = 'invalid';
            }
        }
    }

    motiveCheck() {
        this.isLoading = true;
        let motivoTexto;
        switch (this.selectForm.value.select) {
            case '1':
                motivoTexto = "Perdí mi contraseña";
                break;
            case '2':
                motivoTexto = "Ya no la utilizaré";
                break;
            case '3':
                motivoTexto = "Cambiaré de proveedor";
                break;
            case '4':
                motivoTexto = "Compré otra";
                break;
            case '5':
                motivoTexto = this.selectForm.value.motivo;
                break;
            default:
                break;
        }
        let fileReader = new FileReader();
        let fileString;
        fileReader.readAsDataURL(this.carnet[0]);
        fileReader.onloadend = () => {
            fileString = fileReader.result as string;
            const formData: FormData = new FormData();
            formData.append("carnet", this.carnet[0]);
            formData.append("token", this.responseUser.data.token);
            formData.append("reason", motivoTexto)
            this.apiAutoatencion.generateHES(formData).subscribe(res => {
                this.responseFinal = res;
                this.isLoading = false;
                this.successFunction("anulacion");
            }, err => {
                console.error("err:", err);
                let errCase = errorCodeChecker(err.error);
                this.errorFunction(errCase, 1);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            })
        }
    }

    successFunction(option: string) {
        this.currentStep = 9;
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
            data: {
                kind: option
            }
        })
        dialogRef.afterClosed().subscribe(val => { this.dialog.closeAll() })
    }

    errorFunction(option: string, step: number) {
        this.currentStep = 9;
        const dialogRef = this.dialog.open(WarningDialogComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
            data: {
                kind: option
            }
        })
        dialogRef.afterClosed().subscribe(res => {
            if (res) this.currentStep = step
            else this.dialog.closeAll()
        })
    }

    formatRUT(form: any) {
        this.inputRUT.setValue(formatRUT(form.inputRUT.value));
    }
}
