import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL, environment } from '../../../environments/environment';

export interface IInvoice { }

@Injectable({
    providedIn: 'root',
})
export class ApiAutoatencion {
    private apiKeyDev = 'd6cf4e13-177f-4323-a2d9-e18c77964fe1';
    private apiKeyProd = '6ecf0fa5-b45d-4239-9fb6-e06fbf032069';
    constructor(private http: HttpClient) { }
    private getApiKey(): string {
        if (environment.production) return this.apiKeyProd;
        else return this.apiKeyDev;
    }
    public postSignatureInfo(rut: string, validatePassword: boolean) {
        const url = URL.API_AUTOATENCION;
        const petition = String(url + 'get-signature-info/' + rut);
        let apiKey = this.getApiKey();
        const options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-api-key': apiKey
            },
            body: {
                "validatePassword": validatePassword,
            },
        };
        return this.http.request('POST', petition, options);
    }

    public fowardRequestValidating(body: Object) {
        const url = URL.API_AUTOATENCION;
        const petition = String(url + 'forward-request-validating')
        let apiKey = this.getApiKey();
        const options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-api-key': apiKey
            },
            body: body
        };
        return this.http.request('POST', petition, options);
    }

    public validatingEmail(body: Object) {
        const url = URL.API_AUTOATENCION;
        const petition = String(url + 'validate-email');
        let apiKey = this.getApiKey();
        const options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-api-key': apiKey
            },
            body: body
        };
        return this.http.request('POST', petition, options);
    }

    public generateHES(body: any) {
        const url = URL.API_AUTOATENCION;
        const petition = String(url + 'revoke');
        let apiKey = this.getApiKey();
        const options = {
            headers: {
                'X-api-key': apiKey
            },
            body: body
        }
        return this.http.request('POST', petition, options);
    }
}
