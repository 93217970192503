import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-warning-dialog',
    templateUrl: './warning-dialog.component.html'
})

export class WarningDialogComponent implements OnInit {
    texts = {
        NoRut: "No existe un usuario con el RUT ingresado. Por favor verifique la información y vuelva a intentarlo.",
        NoUser: "No existe una firma asociada al RUT ingresado. Por favor verifique la información y vuelva a intentarlo.",
        NoMail: "El correo electrónico ingresado no corresponde con la información de nuestro sistema. Verifica tu correo electrónico y vuelve a intentarlo.",
        FirmaAntigua: "Los datos ingresados no se encuentra en nuestro sistema. No es posible encontrar información sobre las firmas generadas antes del <b>05-08-2022</b>.",
        NoRefundOld: "Tu firma no se puede reembolsar ya que han pasado más de 60 días desde la compra.",
        NoRefundGen: "Tu firma no se puede reembolsar debido a que ya fue generada.",
        AlreadyRefunded: "No se pueden recuperar los datos debido a que la firma ya fue reembolsada.",
        NotComplete: "El proceso de creación de firma no está completo, por lo que no podemos continuar con este proceso.",
        NoAuth: "Durante la emisión de tu firma no se recibió autorización para guardar la contraseña.",
        BadFieldDefault: "Se ha presentado un error al validar los campos ingresados. Por favor verifique que los datos ingresados son los correctos y vuelva a intentarlo.",
        BadFieldCarnet: "Se ha presentado un error al validar el formato del carnet. Por favor verifique que el formato del archivo es .pdf y vuelva a intentarlo."
    }

    description = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (this.data) {
            this.description = this.texts[this.data.kind]
        }
    }
}
