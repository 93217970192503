import { IDataStrip } from '../../../../../common/interfaces/promotions.interface';
import { AppType } from './../../../../../common/assets/website-data';
import { Component } from "@angular/core";

@Component({
    selector: 'app-strip-wrapper',
    templateUrl: './strip.component.html'
})

export class StripWrapperComponent {
    data: IDataStrip = {
        text: `<span>
            Consigue 1 AÑO de Firma Electrónica Gratis
            <img class="strip-img" src="/assets/images/icons/icon-star-eyes.svg" alt="icon star-eyes"/>
        </span>
        <span>Usa el código: FIRMA3X2
            <img class="strip-img" src="/assets/images/icons/icon-pen.svg" alt="icon pen"/>
            Contrata 3 años y paga solo 2
        </span>`
    }

    get appType() {
        return AppType.chilefirmas
    }
}