import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { errorCodeChecker } from '../../autoatencion-common.component';
import { testRUT } from '../../../../../../common/functions/rut-functions';

@Component({
    selector: 'app-generate-signature',
    templateUrl: './generate-signature.component.html',
})
export class GenerateSignatureComponent implements OnInit {
    public testRUT = testRUT;
    public mailForm: FormGroup;
    public inputRUT: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.pattern(/\b[0-9|.]{1,10}\-[K|k|0-9]/)
    ]);
    public inputMail: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.email
    ]);
    public form: FormGroup = new FormGroup({
        RUT: this.inputRUT,
        mail: this.inputMail,
    });
    ngOnInit() { }
}
