import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from '@ngx-meta/core';
import { isPlatformBrowser } from '@angular/common';

import { URL } from '../../environments/environment';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { ResetEmailPasswordComponent } from './dialogs/reset-email-password/reset-email-password.component';
import { GenerateSignatureComponent } from './dialogs/generate-signature/generate-signature.component';
import { ResendSignatureComponent } from './dialogs/resend-signature/resend-signature.component';
import { CancelRevokeSignatureComponent } from './dialogs/cancel-revoke-signature/cancel-revoke-signature.component';
import { ReturnSignatureComponent } from './dialogs/return-signature/return-signature.component';
import { PdfSignatureComponent } from './dialogs/pdf-signature/pdf-signature.component';
import { DownloadSignatureComponent } from './dialogs/download-signature/download-signature.component';
import { CentralizeSignatureComponent } from './dialogs/centralize-signature/centralize-signature.component';

@Component({
    selector: 'app-autoatencion',
    templateUrl: 'autoatencion.component.html',
    styleUrls: ['./autoatencion.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AutoatencionComponent extends ScrollToClass implements OnInit {
    public URL = URL;
    constructor(
        private _el: ElementRef,
        private dialog: MatDialog,
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        super(_el)
    }

    ngOnInit() {
        this.setMeta();
        this.scrollTop();
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    public openDialogResetEmailPassword(): void {
        const dialogRef = this.dialog.open(ResetEmailPasswordComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogGenerateSignature(): void {
        const dialogRef = this.dialog.open(GenerateSignatureComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogResendSignature(): void {
        const dialogRef = this.dialog.open(ResendSignatureComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogCancelRevokeSignature(): void {
        const dialogRef = this.dialog.open(CancelRevokeSignatureComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogReturnSignature(): void {
        const dialogRef = this.dialog.open(ReturnSignatureComponent, {
            panelClass: 'roundedDialog',
            width: '540px',
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogPdfSignature(): void {
        const dialogRef = this.dialog.open(PdfSignatureComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '1080px',
            minWidth: '90vw',
            height: '90vh'
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogDownloadSignature(): void {
        const dialogRef = this.dialog.open(DownloadSignatureComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '1080px',
            minWidth: '90vw',
            height: '90vh'
        })
        dialogRef.afterClosed().subscribe()
    }

    public openDialogCentralizeSignature(): void {
        const dialogRef = this.dialog.open(CentralizeSignatureComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '1080px',
            minWidth: '90vw',
            height: '90vh'
        })
        dialogRef.afterClosed().subscribe()
    }

    private setMeta(): void {
        this._meta.setTitle('Autoatención Chilefirmas');
        this._meta.setTag('description', 'Aprende cómo firmar con firma electrónica y gestiona tus documentos firmados digitalmente.');
        this._meta.setTag('keywords', 'Chilefirmas, firma electrónica, certificado digital, firma simple, firma digital, haulmer, autoatención, firmar PDF, cargar firma, SII, anular firma, centralizar firma, recuperar contraseña');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.CHILEFIRMAS_WEBSITE + 'assets/images/og/og-autoatencion.png');
        this._meta.setTag('og:image:width', '1200');
        this._meta.setTag('og:image:height', '1200');
        this._meta.setTag('og:image:alt', 'Autoatención Chilefirmas');
    }
}
