import { Component, OnInit, Output, Inject, HostListener, PLATFORM_ID } from '@angular/core';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-payment-forms-wrapper',
    templateUrl: './payment-forms-wrapper.component.html'
})
export class PaymentFormsWrapperComponent implements OnInit {
    @Output() Meta: Meta;
    @Output() TLD = 'cl';
    // TODO cinta promocional cambiar de 130 - 180
    public headerHeight = 130;

    public URL = URL;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.headerHeight = window.innerWidth < 768 ? 64 : 130;
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platform_id: any
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platform_id)) {
            this.headerHeight = window.innerWidth < 768 ? 64 : 130;
        }
        this.Meta = new Meta(
            'Formas de Pago | ChileFirmas',
            'Conoce las formas de pago que ChileFirmas deja a tu disposición. Cancela tus planes y disfrútalos ¡Ahora!',
            'ChileFirmas',
            this.URL.CHILEFIRMAS_WEBSITE + 'assets/og/images/og-formas-de-pago.png',
            'png',
            644,
            374,
            'Formas de Pago | ChileFirmas'
        );

        this.Meta.addKeyword('formas de pagos');
        this.Meta.addKeyword('métodos de pago');

        setTimeout(() => {
            this._document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        }, 100);
    }
}
