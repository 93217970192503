import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-warning-dialog',
    templateUrl: './success-dialog.component.html'
})

export class SuccessDialogComponent implements OnInit {
    texts = {
        anulacion: {
            body: "Tu firma electrónica ha sido anulada con éxito. Recuerda que puedes comprar una firma electrónica ingresando a:",
            link: "www.chilefirmas.cl"
        },
        signGen: {
            body: "Te hemos enviado un correo electrónico para que puedas generar tu firma.",
            link:false
        },
        infoSign:{
            body: "Hemos enviado a tu correo electrónico toda la información de tu firma electrónica.",
            link:false
        },
        solStatus: {
            body: "Nuestro equipo está evaluando tu solicitud, se te informará de su estado a través de correo electrónico en las próximas 48 horas.",
            link:false
        },
        refund: {
            body: "La devolución de tu dinero se verá reflejada en tu cuenta en las próximas 72 horas hábiles.",
            link: false
        }
    }

    description = "";
    refLink = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (this.data) {
            this.description = this.texts[this.data.kind].body
            this.refLink = this.texts[this.data.kind].link
        }
    }
}
