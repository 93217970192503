import { Component } from '@angular/core';
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { Router } from '@angular/router';
import { urlMapping } from '../../route-ids';

@Component({
    selector: 'app-dialog-custom-menu',
    templateUrl: './dialog-custom-menu.component.html'
})
export class DialogCustomMenuComponent {

    public url:string;
    public traceButton2 = traceButton2;

    constructor(
        private _router: Router,
    ) {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
            
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
