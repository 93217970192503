import { ScrollService } from './../services/scroll.service';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CONTACTS } from '../../../../../common/contacts.constants';
import { URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { urlMapping } from '../route-ids';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
    URL = URL;
    contactList = CONTACTS.filter(c => c.country_code === 'cl');
    currentCountryCode = this.contactList[0].country_code;
    public url: string;
    public traceButton2 = traceButton2;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private scrollService: ScrollService,
        private _router: Router
    ) {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
     }

    ngOnInit() {
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country_code === this.currentCountryCode);
        return country[0];
    }

    goToCart() {
        window.location.href = URL.CHILEFIRMAS_CART+'?subproduct=CF';
    }

    scrollTo(target: string): void {
        this.scrollService.updateTarget(target);
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
