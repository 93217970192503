export function errorCodeChecker(error: any) {
    switch (error.error_code) {
        case "ES-3000":
            return "NoRut";
        case "ES-3001":
            return "NoUser";
        case "ES-3002":
            return "FirmaAntigua";
        case "ES-3003":
            return "AlreadyRefunded";
        case "ES-3004":
            return "NotComplete";
        case "ES-3005":
            return "NoAuth";
        case "ES-3007":
            return "NoMail";
        default:
            // Defaults to NoUser for precaution
            if (error.message == "Fields validation fails") {
                if (error.errors.carnet != undefined) return "BadFieldCarnet";
                else return "BadFieldDefault";
            }
            else return "NoUser";
    }
}
