import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {

    subject: BehaviorSubject<string>;
    message: Observable<string>;

    constructor() {
        this.subject = new BehaviorSubject<string>(null);
        this.message = this.subject.asObservable();
    }

    updateTarget(target: string): void {
        this.subject.next(target);
    }

    getMessage(): Observable<string> {
        return this.message;
    }
}
