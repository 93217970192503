import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { errorCodeChecker } from '../../autoatencion-common.component';
import { ApiAutoatencion } from '../../../shared/services/api-autoatencion';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { testRUT, formatRUT } from '../../../../../../common/functions/rut-functions';

@Component({
    selector: 'app-reset-email-password',
    templateUrl: './reset-email-password.component.html',
})
export class ResetEmailPasswordComponent implements OnInit {
    currentStep = 1;
    public testRUT = testRUT;
    public isLoading: boolean = false;
    public inputRUT: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.pattern(/\b[0-9|.]{1,10}\-[K|k|0-9]/),
    ]);
    public inputMail: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.email,
    ]);
    public form: FormGroup = new FormGroup({
        RUT: this.inputRUT,
    });
    public mailForm: FormGroup = new FormGroup({
        mail: this.inputMail,
    });
    public tempMail: string = "";
    private response: any;
    constructor(
        private dialog: MatDialog,
        private apiAutoatencion: ApiAutoatencion
    ) { }

    ngOnInit() {
    }
    nextStep(): void {
        this.currentStep++;
    }
    recoverInfoPassword() {
        this.isLoading = true;
        const RUT = this.form.value.RUT.replaceAll('.', '')
        this.apiAutoatencion.postSignatureInfo(RUT, true).subscribe(res => {
            this.response = res;
            this.tempMail = this.response.data.email_cert;
            this.isLoading = false;
        }, err => {
            let errCase = errorCodeChecker(err.error);
            this.errorFunction(errCase, 1);
            this.response = err;
            this.isLoading = false;
        }, () => {
            this.nextStep()
            this.isLoading = false;
        })
    }
    fowardSign() {
        this.isLoading = true;
        const body = {
            "token": this.response.data.token,
            "email": this.mailForm.value.mail
        }
        this.apiAutoatencion.fowardRequestValidating(body).subscribe(res => {
            this.successFunction("infoSign");
            this.isLoading = false;
        }, err => {
            let errCase = errorCodeChecker(err.error);
            this.errorFunction(errCase, 1);
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        })
    }
    errorFunction(option: string, step: number) {
        this.currentStep = 9;
        const dialogRef = this.dialog.open(WarningDialogComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
            data: {
                kind: option
            }
        })
        dialogRef.afterClosed().subscribe(res => {
            if (res) this.currentStep = step
            else this.dialog.closeAll()
        })
    }
    successFunction(option: string) {
        this.currentStep = 9;
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '540px',
            data: {
                kind: option
            }
        })
        dialogRef.afterClosed().subscribe(val => { this.dialog.closeAll() })
    }
    formatRUT(form: any) {
        this.inputRUT.setValue(formatRUT(form.inputRUT.value));
    }
}
