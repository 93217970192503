import { DialogCustomMenuComponent } from './shared/dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { ScrollService } from './shared/services/scroll.service';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MetaModule, MetaService } from '@ngx-meta/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { CustomMaterialModule } from '../../../common/material.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContactsComponent } from '../../../common/contacts/contacts.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ContactSupportComponent } from '../../../common/contact-support/contact-support.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { SectionPreviewMachineComponent } from './contact-support-wrapper/section-preview-machine/section-preview-machine.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';
import { AutoatencionComponent } from './autoatencion/autoatencion.component';
import { ResetEmailPasswordComponent } from './autoatencion/dialogs/reset-email-password/reset-email-password.component';
import { GenerateSignatureComponent } from './autoatencion/dialogs/generate-signature/generate-signature.component';
import { ResendSignatureComponent } from './autoatencion/dialogs/resend-signature/resend-signature.component';
import { CancelRevokeSignatureComponent } from './autoatencion/dialogs/cancel-revoke-signature/cancel-revoke-signature.component';
import { ReturnSignatureComponent } from './autoatencion/dialogs/return-signature/return-signature.component';
import { PdfSignatureComponent } from './autoatencion/dialogs/pdf-signature/pdf-signature.component';
import { DownloadSignatureComponent } from './autoatencion/dialogs/download-signature/download-signature.component';
import { CentralizeSignatureComponent } from './autoatencion/dialogs/centralize-signature/centralize-signature.component';
import { WarningDialogComponent } from './autoatencion/dialogs/warning-dialog/warning-dialog.component';
import { SuccessDialogComponent } from './autoatencion/dialogs/success-dialog/success-dialog.component';
import { ImmediateManagementComponent } from './home/immediate-management/immediate-management.component';


// import { RaygunErrorHandler } from '../../../common/app.raygun.setup';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { WebsiteCommonModule } from '../../../common/common.module';

//Promotions
import { StripWrapperComponent } from './promotions/strip/strip.component';
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component'

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        ContactsComponent,
        ContactWrapperComponent,
        ContactSupportComponent,
        ContactSupportWrapperComponent,
        SectionPreviewMachineComponent,
        DialogCustomMenuComponent,
        PaymentFormsWrapperComponent,
        AutoatencionComponent,
        ResetEmailPasswordComponent,
        GenerateSignatureComponent,
        ResendSignatureComponent,
        CancelRevokeSignatureComponent,
        ReturnSignatureComponent,
        PdfSignatureComponent,
        DownloadSignatureComponent,
        CentralizeSignatureComponent,
        WarningDialogComponent,
        SuccessDialogComponent,
        ImmediateManagementComponent,
        StripWrapperComponent,
        DialogPromoWrapperComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'chilefirmas' }),
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        MetaModule.forRoot(),
        ReactiveFormsModule,
        CustomMaterialModule,
        NgxCaptchaModule,
        TransferHttpCacheModule,
        DeferLoadModule,
        IconSpriteModule,
        WebsiteCommonModule
    ],
    entryComponents: [
        DialogCustomMenuComponent
    ],
    providers: [
        MetaService,
        ScrollService,
        // {
        //     provide: ErrorHandler,
        //     useClass: RaygunErrorHandler
        // }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
