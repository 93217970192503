import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { testRUT, formatRUT } from '../../../../../../common/functions/rut-functions';

@Component({
    selector: 'app-return-signature',
    templateUrl: './return-signature.component.html'
})

export class ReturnSignatureComponent implements OnInit {
    currentStep = 1;
    public testRUT = testRUT;
    public inputRUTS1: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.pattern(/\b[0-9|.]{1,10}\-[K|k|0-9]/)
    ]);
    public inputMailS1: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.email,
    ]);
    public inputUserS1: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000)
    ]);
    public inputUserS4: FormControl = new FormControl('', [
        Validators.required
    ]);
    public inputUserS5: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000)
    ]);
    public inputRUTS5: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.pattern(/\b[0-9|.]{1,10}\-[K|k|0-9]/)
    ]);
    public inputBank: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000)
    ]);
    public inputCuenta: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000)
    ]);
    public inputNumber: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000)
    ]);
    public inputMailS5: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30000),
        Validators.email
    ]);
    public form1: FormGroup = new FormGroup({
        user: this.inputUserS1,
        RUT: this.inputRUTS1,
        mail: this.inputMailS1
    });
    public form4: FormGroup = new FormGroup({
        user: this.inputUserS4
    });
    public form5: FormGroup = new FormGroup({
        user: this.inputUserS5,
        RUT: this.inputRUTS5,
        bank: this.inputBank,
        cuenta: this.inputCuenta,
        number: this.inputNumber,
        mail: this.inputMailS5
    });

    constructor(private dialogRef: MatDialogRef<ReturnSignatureComponent>) { }

    ngOnInit() {
        this.checkWidth();
    }
    nextStep(stepNumber: number): void {
        if (this.currentStep == 4 && this.form4.value.user == 'current') {
            this.currentStep = stepNumber ?? this.currentStep + 2
        }
        else this.currentStep = stepNumber ?? this.currentStep + 1
        this.checkWidth();
    }
    backStep(stepNumber: number): void {
        if (this.currentStep == 4) this.currentStep = stepNumber ?? this.currentStep - 2
        else this.currentStep = stepNumber ?? this.currentStep - 1
        this.checkWidth();
    }
    setWidth(width: number): void {
        this.dialogRef.updateSize(`${width}px`, 'auto');
    }

    checkWidth(): void {
        switch (this.currentStep) {
            case (2):
            case (4):
            case (5):
                this.setWidth(848);
                break;
            default:
                this.setWidth(516);
                break;
        }
    }
    firstStep() {
        // console.log("form1:", this.form1)
    }
}
