import { IModalData } from './../../../common/interfaces/promotions.interface';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component';
import { Router } from '@angular/router';
import { ScrollToClass } from '../../../common/scrollTo.class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends ScrollToClass implements AfterViewInit {
  //TODO: Cinta promocional
  public stripPromotion: boolean = false;
  //TODO: Modal promocional
  private dialogPromo = {
    available: false,
    timeOut: 5000
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private dialog: MatDialog,
    private _router: Router,
    private _el: ElementRef
  ) {
    super(_el);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && this.dialogPromo.available) {
      setTimeout(() => {
        if (this.dialogPromo.available) {
          this.openDialogPromo();
        }
      }, this.dialogPromo.timeOut);
    }
  }

  public openDialogPromo() {
    const dialogRef = this.dialog.open(DialogPromoWrapperComponent, {
      hasBackdrop: true,
      disableClose: true,
      id: 'promocionalModalCF',
      maxWidth: '',
      width: '632px',
      data: { status: false, prCode: '' }
    });

    dialogRef.afterClosed().subscribe((res: IModalData) => {
      if (res.status) {
        this.scrollToSection('price-scroll-ts')
        sessionStorage.setItem('prCode', res.prCode);

      }
    });
  }
}
